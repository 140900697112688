import React from 'react'
import styled from 'styled-components'

interface WrapperProps {
    children: React.ReactNode;
}

const Container = styled.div`
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 30px;
    &::before { 
        flex: 1;
        content: '';
      }
    &::after { 
        flex: 2;
        content: '';
      }

      @media only screen and (min-width: 992px) {
        & * {
            max-width: 550px;
        }
      }
`

const Wrapper = ({children}: WrapperProps) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default Wrapper
