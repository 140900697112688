import styled from "styled-components";

const Text1 = styled.p`
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
`

const TinyText = styled.p`
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    opacity: 0.72;
`

export { Text1, TinyText }
