import React from 'react'
import styled from 'styled-components'
import { Title2 } from '../atoms/Title'
import Logo from '../atoms/Logo'
import { Text1 } from '../atoms/Text'
import Wrapper from '../atoms/Wrapper'


const EmailConfirmed = () => {
    return (
        <Wrapper>
            <Logo size={72} />
            <Title2>Email Address Confirmed</Title2>
            <Text1>Return to the Kami Hub app and login to continue.</Text1>
        </Wrapper>
    )
}

export default EmailConfirmed
