import { Button, Input } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Text1, TinyText } from '../atoms/Text'
import { Title2 } from '../atoms/Title'
import Wrapper from '../atoms/Wrapper'

const NexButton = styled(Button)`
    margin-top: 20px;
    width: 100%;
`

const ResetPasswordInput = () => {
    return (
        <Wrapper>
            <Title2>Choose a password</Title2>
            <Input size="large" placeholder="Specific Info ex. Email" style={{backgroundColor: 'transparent'}} />
            <TinyText style={{marginTop: '15px'}}>Your password must be more than 8 characters and contain at least one upper case letter, one lowercase letter, and one number.</TinyText>
            <NexButton type="primary" size="large">Set Password</NexButton>
        </Wrapper>
    )
}

export default ResetPasswordInput
