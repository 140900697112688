import React from 'react'
import Logo from '../atoms/Logo'
import { Text1 } from '../atoms/Text'
import { Title2 } from '../atoms/Title'
import Wrapper from '../atoms/Wrapper'

const EmailVerificationSent = () => {
    return (
        <Wrapper>
            <Logo size={72} />
            <Title2>Verification Email Sent</Title2>
            <Text1>We’ve sent an email to [%s:email] with a confirmation link inside. Please open the email and click the link to continue.</Text1>
        </Wrapper>
    )
}

export default EmailVerificationSent
