import axios, {AxiosInstance} from 'axios';
import urls from '../urls';


/**
 * Create multiple axios instances with different base URLs for easier
 * communication with different services.
 * Base URLs are dependent on current environment.
 * */

export const turingApi: AxiosInstance = axios.create({
  baseURL: urls.turingBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const loginApi: AxiosInstance = axios.create({
  baseURL: urls.loginBaseUrl,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

turingApi.interceptors.request.use((request) => {
  if (request && request.headers) request.headers.Authorization = `Bearer ${localStorage.getItem('Authorization')}`;
  return request;
});

turingApi.interceptors.response.use(response => {
  response = response.data;
  return response;
}, error => {   
  if ((error.response.status === 401) || (error.response.status === 403)) {
      localStorage.removeItem('Authorization');
      window.location.href = '/';
  }
  return Promise.reject(error);
});


export const apiInstances = [turingApi, loginApi];
