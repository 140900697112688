import decodeEnv from './decodeEnv';

// store current environment mode into the variable
const env = decodeEnv(process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV) || 'dev'

// expose specific env config variables
const envConfig = {
  env,
  callbackUrl: process.env.REACT_APP_CALLBACK_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  loginUrl: process.env.REACT_APP_LOGIN_URL,
  turingUrl: process.env.REACT_APP_TURING_URL,
};

export default envConfig
