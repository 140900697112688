import React from 'react'
import styled from "styled-components";
import LOGO from '../assets/Logo.svg'

interface IMGProps {
    size?: number;
}

const IMG = styled.img`
    width: ${(props: IMGProps) => props.size ? props.size+'px' : '100%'};
    height: ${(props: IMGProps) => props.size ? props.size+'px' : '100%'};
`

const Logo = ({size}: IMGProps) => {
    return (
        <IMG src={LOGO} size={size}/>
    )
}

export default Logo

