import React from 'react'
import styled from 'styled-components'
import { Title2 } from '../atoms/Title'
import AlertRed from '../assets/alert-red.svg'
import { Text1 } from '../atoms/Text'
import Wrapper from '../atoms/Wrapper'


const InvalidResetPasswordLink = () => {
    return (
        <Wrapper>
            <img src={AlertRed} />
            <Title2>Invalid Link</Title2>
            <Text1>The link you clicked is either expired or invalid. Click the button below to resend a new link.</Text1>
        </Wrapper>
    )
}

export default InvalidResetPasswordLink
