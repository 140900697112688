import { UsersApi } from "../swagger/turing"
import { turingApi, loginApi } from "./axios"
import urls from "../urls"
import qs from 'qs'
import envConfig from "../utils/config"

export const usersApi = new UsersApi(undefined, urls.turingBaseUrl, turingApi)

export const getTags = async () => {
    const response = await turingApi.get('/tags/all')
    return response
}


export const getVideos = async () => {
    const response = await turingApi.get('/event_types/fall/media')
    return response
}

export const tagVideo = async (data:any) => {
    const response = await turingApi.patch('/video/action', data)
    return response
}

export const login = async (code:string) => {
    const response = await loginApi.post('/auth/realms/verification/protocol/openid-connect/token', 
    qs.stringify({ client_secret: envConfig.clientSecret, client_id: envConfig.clientId, grant_type: 'authorization_code', code, redirect_uri: `${envConfig.callbackUrl}/get-token` }),    
)
    return response
}
