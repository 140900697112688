import styled from 'styled-components'

const Title2 = styled.h2`
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
`

export { Title2 }
