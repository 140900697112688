import React, { useEffect } from 'react'
import queryString from 'query-string';
import { login } from '../api/turing-api';
import { get } from 'lodash';
import { useHistory } from 'react-router';

const GetTokenPage = (props:any) => {

    let params:any = queryString.parse(props.location.search)
    const history = useHistory()

    const getToken = async () => {
        try {
            const response = await login(params.code)
            localStorage.setItem('Authorization', get(response, 'data.access_token', ''))
            history.push('/dashboard')
        } catch (error) {
            console.log('error: ', error);
        }
    }
        
    useEffect(() => {
        getToken()
    }, [])
    return (
        <div>
            Loading...
        </div>
    )
}

export default GetTokenPage
