import React, { Suspense } from 'react'
import { createBrowserHistory } from 'history'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Spin } from 'antd';
import PublicRoute from './PublicRoute';
import EmailConfirmed from './containers/EmailConfirmed';
import ResetPasswordSuccess from './containers/ResetPasswordSuccess';
import InvalidResetPasswordLink from './containers/InvalidResetPasswordLink';
import EmailVerificationInput from './containers/EmailVerificationInput';
import EmailVerificationSent from './containers/EmailVerificationSent';
import ResetEmailSent from './containers/ResetEmailSent';
import ResetPasswordInput from './containers/ResetPasswordInput';
import GetTokenPage from './containers/GetTokenPage';
const PrivateRoute = React.lazy(() => import('./PrivateRoute'))
const LoginPage = React.lazy(() => import('./containers/LoginPage'))
const SignupPage = React.lazy(() => import('./containers/SignupPage'))
const DashboardPage = React.lazy(() => import('./containers/DashboardPage'))

const history = createBrowserHistory()


const Router = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Spin size="large" />}>
            <Switch>
                {/* <PublicRoute
                exact
                path="/"
                component={(props:any) => <LoginPage {...props} />}
                />
                <PublicRoute
                exact
                path="/sign-up"
                component={(props:any) => <SignupPage {...props} />}
                /> */}
                <PublicRoute
                exact
                path="/email-confirmed"
                component={(props:any) => <EmailConfirmed {...props} />}
                />
                <PublicRoute
                exact
                path="/invalid-reset-password-link"
                component={(props:any) => <InvalidResetPasswordLink {...props} />}
                />
                <PublicRoute
                exact
                path="/reset-password-success"
                component={(props:any) => <ResetPasswordSuccess {...props} />}
                />
                <PublicRoute
                exact
                path="/email-verification-input"
                component={(props:any) => <EmailVerificationInput {...props} />}
                />
                <PublicRoute
                exact
                path="/reset-password-input"
                component={(props:any) => <ResetPasswordInput {...props} />}
                />
                <PublicRoute
                exact
                path="/email-verification-sent"
                component={(props:any) => <EmailVerificationSent {...props} />}
                />
                <PublicRoute
                exact
                path="/reset-email-sent"
                component={(props:any) => <ResetEmailSent {...props} />}
                />
                <PublicRoute
                exact
                path="/"
                component={(props:any) => <LoginPage {...props} />}
                />
                <PublicRoute
                exact
                path="/get-token"
                component={(props:any) => <GetTokenPage {...props} />}
                />
                <PrivateRoute
                exact
                path="/dashboard"
                component={(props:any) => <DashboardPage {...props} />}
                />
            </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

export default Router
