/* tslint:disable */
/* eslint-disable */
/**
 * Turing APIs
 * Turing APIs
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CoreAPIError
 */
export interface CoreAPIError {
    /**
     * Unique value to identify the error type. Ideally a URI reference
     * @type {string}
     * @memberof CoreAPIError
     */
    error?: string;
    /**
     * A short, human-readable summary of the error type
     * @type {string}
     * @memberof CoreAPIError
     */
    error_description?: string;
    /**
     * The HTTP status code
     * @type {number}
     * @memberof CoreAPIError
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface CoreTimeNowResponse
 */
export interface CoreTimeNowResponse {
    /**
     * 
     * @type {string}
     * @memberof CoreTimeNowResponse
     */
    time?: string;
}
/**
 * 
 * @export
 * @interface DevicesCaptureVideoAck
 */
export interface DevicesCaptureVideoAck {
    /**
     * 
     * @type {string}
     * @memberof DevicesCaptureVideoAck
     */
    reference_id?: string;
}
/**
 * 
 * @export
 * @interface DevicesCaptureVideoResponse
 */
export interface DevicesCaptureVideoResponse {
    /**
     * 
     * @type {Array<DevicesDeviceVideoDetail>}
     * @memberof DevicesCaptureVideoResponse
     */
    device_video_details?: Array<DevicesDeviceVideoDetail>;
}
/**
 * 
 * @export
 * @interface DevicesCertificateResponse
 */
export interface DevicesCertificateResponse {
    /**
     * 
     * @type {string}
     * @memberof DevicesCertificateResponse
     */
    ca_cert_url?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesCertificateResponse
     */
    certificate?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesCertificateResponse
     */
    iot_endpoint_address?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesCertificateResponse
     */
    iot_role_alias?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesCertificateResponse
     */
    private_key?: string;
}
/**
 * 
 * @export
 * @interface DevicesCreateOrgDeviceRequest
 */
export interface DevicesCreateOrgDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof DevicesCreateOrgDeviceRequest
     */
    bind_key: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesCreateOrgDeviceRequest
     */
    d_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesCreateOrgDeviceRequest
     */
    is_positioned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevicesCreateOrgDeviceRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface DevicesDIdGenMapResponse
 */
export interface DevicesDIdGenMapResponse {
    /**
     * 
     * @type {string}
     * @memberof DevicesDIdGenMapResponse
     */
    did?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesDIdGenMapResponse
     */
    key?: string;
}
/**
 * 
 * @export
 * @interface DevicesDIdGenerationResponse
 */
export interface DevicesDIdGenerationResponse {
    /**
     * 
     * @type {number}
     * @memberof DevicesDIdGenerationResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<DevicesDIdGenMapResponse>}
     * @memberof DevicesDIdGenerationResponse
     */
    did_list?: Array<DevicesDIdGenMapResponse>;
}
/**
 * 
 * @export
 * @interface DevicesDIdPartnerMappingResponse
 */
export interface DevicesDIdPartnerMappingResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof DevicesDIdPartnerMappingResponse
     */
    mapped?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DevicesDIdPartnerMappingResponse
     */
    unmapped?: Array<string>;
}
/**
 * 
 * @export
 * @interface DevicesDeviceSettingResponse
 */
export interface DevicesDeviceSettingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    animal_detect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    baby_detect?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceSettingResponse
     */
    day_night?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    face_detect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    fall_detect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    fw_downgrade?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceSettingResponse
     */
    fw_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    human_detect?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DevicesDeviceSettingResponse
     */
    mic_volume?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    mirror_flip?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    motion_detect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    ota_auto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    ota_enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceSettingResponse
     */
    ota_version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    record_audio?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceSettingResponse
     */
    record_mode?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceSettingResponse
     */
    resolution?: string;
    /**
     * 
     * @type {number}
     * @memberof DevicesDeviceSettingResponse
     */
    sensitivity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesDeviceSettingResponse
     */
    vehicle_detect?: boolean;
}
/**
 * 
 * @export
 * @interface DevicesDeviceVideoDetail
 */
export interface DevicesDeviceVideoDetail {
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceVideoDetail
     */
    did?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceVideoDetail
     */
    snapshot_url?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceVideoDetail
     */
    status?: DevicesDeviceVideoDetailStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DevicesDeviceVideoDetail
     */
    video_url?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DevicesDeviceVideoDetailStatusEnum {
    Created = 'CREATED',
    Processing = 'PROCESSING',
    Completed = 'COMPLETED',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface DevicesEventRequest
 */
export interface DevicesEventRequest {
    /**
     * 
     * @type {number}
     * @memberof DevicesEventRequest
     */
    end_time?: number;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventRequest
     */
    event_type_name: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventRequest
     */
    file_type?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventRequest
     */
    file_url?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventRequest
     */
    reference_id?: string;
    /**
     * 
     * @type {number}
     * @memberof DevicesEventRequest
     */
    start_time?: number;
}
/**
 * 
 * @export
 * @interface DevicesEventResponse
 */
export interface DevicesEventResponse {
    /**
     * 
     * @type {string}
     * @memberof DevicesEventResponse
     */
    d_id: string;
    /**
     * 
     * @type {number}
     * @memberof DevicesEventResponse
     */
    end_time?: number;
    /**
     * 
     * @type {EventsEventTypeResponse}
     * @memberof DevicesEventResponse
     */
    event_type: EventsEventTypeResponse;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventResponse
     */
    event_type_name: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventResponse
     */
    file_type?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventResponse
     */
    file_url?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventResponse
     */
    org_id: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesEventResponse
     */
    reference_id?: string;
    /**
     * 
     * @type {number}
     * @memberof DevicesEventResponse
     */
    start_time?: number;
}
/**
 * 
 * @export
 * @interface DevicesEventStreamingURLResponse
 */
export interface DevicesEventStreamingURLResponse {
    /**
     * 
     * @type {string}
     * @memberof DevicesEventStreamingURLResponse
     */
    video_url: string;
}
/**
 * 
 * @export
 * @interface DevicesOrgDeviceResponse
 */
export interface DevicesOrgDeviceResponse {
    /**
     * 
     * @type {string}
     * @memberof DevicesOrgDeviceResponse
     */
    bind_key?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesOrgDeviceResponse
     */
    d_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DevicesOrgDeviceResponse
     */
    is_positioned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevicesOrgDeviceResponse
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface DevicesPartnerInfo
 */
export interface DevicesPartnerInfo {
    /**
     * 
     * @type {string}
     * @memberof DevicesPartnerInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesPartnerInfo
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface DevicesPreSignedUploadUrlResponse
 */
export interface DevicesPreSignedUploadUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof DevicesPreSignedUploadUrlResponse
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface DevicesSuccessResponse
 */
export interface DevicesSuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof DevicesSuccessResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesSuccessResponse
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface DevicesUpdateEventRequest
 */
export interface DevicesUpdateEventRequest {
    /**
     * 
     * @type {number}
     * @memberof DevicesUpdateEventRequest
     */
    end_time?: number;
    /**
     * 
     * @type {string}
     * @memberof DevicesUpdateEventRequest
     */
    event_type_name: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesUpdateEventRequest
     */
    file_type?: string;
    /**
     * 
     * @type {string}
     * @memberof DevicesUpdateEventRequest
     */
    file_url?: string;
    /**
     * 
     * @type {number}
     * @memberof DevicesUpdateEventRequest
     */
    start_time?: number;
}
/**
 * 
 * @export
 * @interface DevicesUpdateOrgDeviceRequest
 */
export interface DevicesUpdateOrgDeviceRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DevicesUpdateOrgDeviceRequest
     */
    is_positioned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DevicesUpdateOrgDeviceRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface DevicesVertex
 */
export interface DevicesVertex {
    /**
     * 
     * @type {number}
     * @memberof DevicesVertex
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof DevicesVertex
     */
    y: number;
}
/**
 * 
 * @export
 * @interface DevicesZone
 */
export interface DevicesZone {
    /**
     * 
     * @type {Array<DevicesVertex>}
     * @memberof DevicesZone
     */
    vertices: Array<DevicesVertex>;
    /**
     * 
     * @type {string}
     * @memberof DevicesZone
     */
    zone_type: DevicesZoneZoneTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DevicesZoneZoneTypeEnum {
    Include = 'INCLUDE',
    Exclude = 'EXCLUDE'
}

/**
 * 
 * @export
 * @interface EventsEventTypeRequest
 */
export interface EventsEventTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsEventTypeRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof EventsEventTypeRequest
     */
    post_buffer: number;
    /**
     * 
     * @type {number}
     * @memberof EventsEventTypeRequest
     */
    pre_buffer: number;
    /**
     * 
     * @type {string}
     * @memberof EventsEventTypeRequest
     */
    type?: EventsEventTypeRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EventsEventTypeRequestTypeEnum {
    AiAnalytics = 'AI_ANALYTICS',
    UserTriggered = 'USER_TRIGGERED',
    System = 'SYSTEM',
    Audit = 'AUDIT'
}

/**
 * 
 * @export
 * @interface EventsEventTypeResponse
 */
export interface EventsEventTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof EventsEventTypeResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof EventsEventTypeResponse
     */
    post_buffer: number;
    /**
     * 
     * @type {number}
     * @memberof EventsEventTypeResponse
     */
    pre_buffer: number;
    /**
     * 
     * @type {string}
     * @memberof EventsEventTypeResponse
     */
    type?: EventsEventTypeResponseTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EventsEventTypeResponseTypeEnum {
    AiAnalytics = 'AI_ANALYTICS',
    UserTriggered = 'USER_TRIGGERED',
    System = 'SYSTEM',
    Audit = 'AUDIT'
}

/**
 * 
 * @export
 * @interface EventsPlatformEventTypeRequest
 */
export interface EventsPlatformEventTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsPlatformEventTypeRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof EventsPlatformEventTypeRequest
     */
    post_buffer: number;
    /**
     * 
     * @type {number}
     * @memberof EventsPlatformEventTypeRequest
     */
    pre_buffer: number;
    /**
     * 
     * @type {string}
     * @memberof EventsPlatformEventTypeRequest
     */
    type?: EventsPlatformEventTypeRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EventsPlatformEventTypeRequestTypeEnum {
    AiAnalytics = 'AI_ANALYTICS',
    UserTriggered = 'USER_TRIGGERED',
    System = 'SYSTEM',
    Audit = 'AUDIT'
}

/**
 * 
 * @export
 * @interface EventsPlatformEventTypeResponse
 */
export interface EventsPlatformEventTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof EventsPlatformEventTypeResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof EventsPlatformEventTypeResponse
     */
    post_buffer: number;
    /**
     * 
     * @type {number}
     * @memberof EventsPlatformEventTypeResponse
     */
    pre_buffer: number;
    /**
     * 
     * @type {string}
     * @memberof EventsPlatformEventTypeResponse
     */
    type?: EventsPlatformEventTypeResponseTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EventsPlatformEventTypeResponseTypeEnum {
    AiAnalytics = 'AI_ANALYTICS',
    UserTriggered = 'USER_TRIGGERED',
    System = 'SYSTEM',
    Audit = 'AUDIT'
}

/**
 * 
 * @export
 * @interface EventsUpdateEventTypeRequest
 */
export interface EventsUpdateEventTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof EventsUpdateEventTypeRequest
     */
    post_buffer?: number;
    /**
     * 
     * @type {number}
     * @memberof EventsUpdateEventTypeRequest
     */
    pre_buffer?: number;
    /**
     * 
     * @type {string}
     * @memberof EventsUpdateEventTypeRequest
     */
    type?: EventsUpdateEventTypeRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EventsUpdateEventTypeRequestTypeEnum {
    AiAnalytics = 'AI_ANALYTICS',
    UserTriggered = 'USER_TRIGGERED',
    System = 'SYSTEM',
    Audit = 'AUDIT'
}

/**
 * 
 * @export
 * @interface EventsUpdatePlatformEventTypeRequest
 */
export interface EventsUpdatePlatformEventTypeRequest {
    /**
     * 
     * @type {number}
     * @memberof EventsUpdatePlatformEventTypeRequest
     */
    post_buffer?: number;
    /**
     * 
     * @type {number}
     * @memberof EventsUpdatePlatformEventTypeRequest
     */
    pre_buffer?: number;
    /**
     * 
     * @type {string}
     * @memberof EventsUpdatePlatformEventTypeRequest
     */
    type?: EventsUpdatePlatformEventTypeRequestTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EventsUpdatePlatformEventTypeRequestTypeEnum {
    AiAnalytics = 'AI_ANALYTICS',
    UserTriggered = 'USER_TRIGGERED',
    System = 'SYSTEM',
    Audit = 'AUDIT'
}

/**
 * 
 * @export
 * @interface OrgsAddress
 */
export interface OrgsAddress {
    /**
     * 
     * @type {string}
     * @memberof OrgsAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsAddress
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsAddress
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsAddress
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsAddress
     */
    zipcode: string;
}
/**
 * 
 * @export
 * @interface OrgsBindKeyResponse
 */
export interface OrgsBindKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof OrgsBindKeyResponse
     */
    bind_key?: string;
}
/**
 * 
 * @export
 * @interface OrgsCreateOrgRequest
 */
export interface OrgsCreateOrgRequest {
    /**
     * 
     * @type {OrgsAddress}
     * @memberof OrgsCreateOrgRequest
     */
    address: OrgsAddress;
    /**
     * 
     * @type {object}
     * @memberof OrgsCreateOrgRequest
     */
    metadata?: object;
    /**
     * 
     * @type {string}
     * @memberof OrgsCreateOrgRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface OrgsOrgResponse
 */
export interface OrgsOrgResponse {
    /**
     * 
     * @type {OrgsAddress}
     * @memberof OrgsOrgResponse
     */
    address: OrgsAddress;
    /**
     * 
     * @type {Array<DevicesOrgDeviceResponse>}
     * @memberof OrgsOrgResponse
     */
    devices: Array<DevicesOrgDeviceResponse>;
    /**
     * 
     * @type {string}
     * @memberof OrgsOrgResponse
     */
    id: string;
    /**
     * 
     * @type {object}
     * @memberof OrgsOrgResponse
     */
    metadata?: object;
    /**
     * 
     * @type {string}
     * @memberof OrgsOrgResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsOrgResponse
     */
    owner: string;
}
/**
 * 
 * @export
 * @interface OrgsStreamCredentialRequest
 */
export interface OrgsStreamCredentialRequest {
    /**
     * 
     * @type {string}
     * @memberof OrgsStreamCredentialRequest
     */
    unique_id: string;
}
/**
 * 
 * @export
 * @interface OrgsStreamCredentialResponse
 */
export interface OrgsStreamCredentialResponse {
    /**
     * 
     * @type {string}
     * @memberof OrgsStreamCredentialResponse
     */
    access_key_id: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsStreamCredentialResponse
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsStreamCredentialResponse
     */
    secret_access_key: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsStreamCredentialResponse
     */
    session_token: string;
}
/**
 * 
 * @export
 * @interface OrgsUpdateAddress
 */
export interface OrgsUpdateAddress {
    /**
     * 
     * @type {string}
     * @memberof OrgsUpdateAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsUpdateAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsUpdateAddress
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsUpdateAddress
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsUpdateAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgsUpdateAddress
     */
    zipcode?: string;
}
/**
 * 
 * @export
 * @interface OrgsUpdateOrgRequest
 */
export interface OrgsUpdateOrgRequest {
    /**
     * 
     * @type {OrgsUpdateAddress}
     * @memberof OrgsUpdateOrgRequest
     */
    address?: OrgsUpdateAddress;
    /**
     * 
     * @type {object}
     * @memberof OrgsUpdateOrgRequest
     */
    metadata?: object;
    /**
     * 
     * @type {string}
     * @memberof OrgsUpdateOrgRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface SubjectsSubjectRequest
 */
export interface SubjectsSubjectRequest {
    /**
     * 
     * @type {number}
     * @memberof SubjectsSubjectRequest
     */
    age: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectRequest
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectRequest
     */
    relationship: SubjectsSubjectRequestRelationshipEnum;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectRequest
     */
    specific_relationship: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SubjectsSubjectRequestRelationshipEnum {
    LovedOne = 'LOVED_ONE',
    Self = 'SELF',
    Patient = 'PATIENT'
}

/**
 * 
 * @export
 * @interface SubjectsSubjectResponse
 */
export interface SubjectsSubjectResponse {
    /**
     * 
     * @type {number}
     * @memberof SubjectsSubjectResponse
     */
    age?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectResponse
     */
    relationship?: SubjectsSubjectResponseRelationshipEnum;
    /**
     * 
     * @type {string}
     * @memberof SubjectsSubjectResponse
     */
    specific_relationship?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SubjectsSubjectResponseRelationshipEnum {
    LovedOne = 'LOVED_ONE',
    Self = 'SELF',
    Patient = 'PATIENT'
}

/**
 * 
 * @export
 * @interface SubjectsUpdateSubjectRequest
 */
export interface SubjectsUpdateSubjectRequest {
    /**
     * 
     * @type {number}
     * @memberof SubjectsUpdateSubjectRequest
     */
    age?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectsUpdateSubjectRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectsUpdateSubjectRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectsUpdateSubjectRequest
     */
    relationship?: SubjectsUpdateSubjectRequestRelationshipEnum;
    /**
     * 
     * @type {string}
     * @memberof SubjectsUpdateSubjectRequest
     */
    specific_relationship?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SubjectsUpdateSubjectRequestRelationshipEnum {
    LovedOne = 'LOVED_ONE',
    Self = 'SELF',
    Patient = 'PATIENT'
}

/**
 * 
 * @export
 * @interface UsersCreateUserRequest
 */
export interface UsersCreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersCreateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersCreateUserRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersCreateUserRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersCreateUserRequest
     */
    username: string;
}
/**
 * 
 * @export
 * @interface UsersPartner
 */
export interface UsersPartner {
    /**
     * 
     * @type {string}
     * @memberof UsersPartner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartner
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface UsersReplaceUserRequest
 */
export interface UsersReplaceUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersReplaceUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersReplaceUserRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersReplaceUserRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersReplaceUserRequest
     */
    username: string;
}
/**
 * 
 * @export
 * @interface UsersSendResponse
 */
export interface UsersSendResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UsersSendResponse
     */
    sent?: boolean;
}
/**
 * 
 * @export
 * @interface UsersSetPasswordRequest
 */
export interface UsersSetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersSetPasswordRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface UsersSignUpUserRequest
 */
export interface UsersSignUpUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersSignUpUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UsersSignUpUserRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UsersSignUpUserRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersSignUpUserRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UsersSignUpUserRequest
     */
    username: string;
}
/**
 * 
 * @export
 * @interface UsersTokenExchangeRequest
 */
export interface UsersTokenExchangeRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeRequest
     */
    audience: string;
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeRequest
     */
    requested_token_type: string;
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeRequest
     */
    subject_token: string;
}
/**
 * 
 * @export
 * @interface UsersTokenExchangeResponse
 */
export interface UsersTokenExchangeResponse {
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeResponse
     */
    access_token?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersTokenExchangeResponse
     */
    expires_in?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeResponse
     */
    id_token?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersTokenExchangeResponse
     */
    not_before_policy?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersTokenExchangeResponse
     */
    refresh_expires_in?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeResponse
     */
    refresh_token?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeResponse
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeResponse
     */
    session_state?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersTokenExchangeResponse
     */
    token_type?: string;
}
/**
 * 
 * @export
 * @interface UsersUpdateUserRequest
 */
export interface UsersUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserRequest
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersUpdateUserRequest
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface UsersUserExistsInResponse
 */
export interface UsersUserExistsInResponse {
    /**
     * 
     * @type {Array<UsersPartner>}
     * @memberof UsersUserExistsInResponse
     */
    partners?: Array<UsersPartner>;
}
/**
 * 
 * @export
 * @interface UsersUserResponse
 */
export interface UsersUserResponse {
    /**
     * 
     * @type {string}
     * @memberof UsersUserResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersUserResponse
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersUserResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersUserResponse
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersUserResponse
     */
    username?: string;
}

/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get partner info of an existing device
         * @summary Get partner info of an existing device
         * @param {string} dId DId
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesDIdPartnerInfoGet: async (dId: string, partnerId?: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('devicesDIdPartnerInfoGet', 'dId', dId)
            const localVarPath = `/devices/{d_id}/partner-info`
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate DId\'s and its associated key. DID consists of 20 alphanumeric characters.
         * @summary Generate DId\'s and its associated key
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesGenerateDidsPost: async (clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/generate-dids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Map DId\'s with partner
         * @summary Map DId\'s with partner
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesMapDidsPost: async (clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/map-dids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create certificate info of an existing device
         * @summary Create certificate info of an existing device
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdCertificatesPost: async (orgId: string, dId: string, partnerId?: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdCertificatesPost', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdCertificatesPost', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/certificates`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current time epoch in seconds in UTC
         * @summary Get the current time epoch in seconds in UTC
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timenowGet: async (partnerId: string, clientId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('timenowGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('timenowGet', 'clientId', clientId)
            const localVarPath = `/timenow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get partner info of an existing device
         * @summary Get partner info of an existing device
         * @param {string} dId DId
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicesDIdPartnerInfoGet(dId: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesPartnerInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicesDIdPartnerInfoGet(dId, partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate DId\'s and its associated key. DID consists of 20 alphanumeric characters.
         * @summary Generate DId\'s and its associated key
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicesGenerateDidsPost(clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesDIdGenerationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicesGenerateDidsPost(clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Map DId\'s with partner
         * @summary Map DId\'s with partner
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async devicesMapDidsPost(clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesDIdPartnerMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.devicesMapDidsPost(clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create certificate info of an existing device
         * @summary Create certificate info of an existing device
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdCertificatesPost(orgId: string, dId: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesCertificateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdCertificatesPost(orgId, dId, partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the current time epoch in seconds in UTC
         * @summary Get the current time epoch in seconds in UTC
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timenowGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoreTimeNowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timenowGet(partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicesApiFp(configuration)
    return {
        /**
         * Get partner info of an existing device
         * @summary Get partner info of an existing device
         * @param {string} dId DId
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesDIdPartnerInfoGet(dId: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<DevicesPartnerInfo> {
            return localVarFp.devicesDIdPartnerInfoGet(dId, partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate DId\'s and its associated key. DID consists of 20 alphanumeric characters.
         * @summary Generate DId\'s and its associated key
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesGenerateDidsPost(clientId?: string, clientSecret?: string, options?: any): AxiosPromise<DevicesDIdGenerationResponse> {
            return localVarFp.devicesGenerateDidsPost(clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Map DId\'s with partner
         * @summary Map DId\'s with partner
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        devicesMapDidsPost(clientId?: string, clientSecret?: string, options?: any): AxiosPromise<DevicesDIdPartnerMappingResponse> {
            return localVarFp.devicesMapDidsPost(clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Create certificate info of an existing device
         * @summary Create certificate info of an existing device
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdCertificatesPost(orgId: string, dId: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<DevicesCertificateResponse> {
            return localVarFp.orgsOrgIdDevicesDIdCertificatesPost(orgId, dId, partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current time epoch in seconds in UTC
         * @summary Get the current time epoch in seconds in UTC
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timenowGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): AxiosPromise<CoreTimeNowResponse> {
            return localVarFp.timenowGet(partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI {
    /**
     * Get partner info of an existing device
     * @summary Get partner info of an existing device
     * @param {string} dId DId
     * @param {string} [partnerId] Partner Id
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public devicesDIdPartnerInfoGet(dId: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any) {
        return DevicesApiFp(this.configuration).devicesDIdPartnerInfoGet(dId, partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate DId\'s and its associated key. DID consists of 20 alphanumeric characters.
     * @summary Generate DId\'s and its associated key
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public devicesGenerateDidsPost(clientId?: string, clientSecret?: string, options?: any) {
        return DevicesApiFp(this.configuration).devicesGenerateDidsPost(clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Map DId\'s with partner
     * @summary Map DId\'s with partner
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public devicesMapDidsPost(clientId?: string, clientSecret?: string, options?: any) {
        return DevicesApiFp(this.configuration).devicesMapDidsPost(clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create certificate info of an existing device
     * @summary Create certificate info of an existing device
     * @param {string} orgId Org Id
     * @param {string} dId DId
     * @param {string} [partnerId] Partner Id
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public orgsOrgIdDevicesDIdCertificatesPost(orgId: string, dId: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any) {
        return DevicesApiFp(this.configuration).orgsOrgIdDevicesDIdCertificatesPost(orgId, dId, partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current time epoch in seconds in UTC
     * @summary Get the current time epoch in seconds in UTC
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public timenowGet(partnerId: string, clientId: string, clientSecret?: string, options?: any) {
        return DevicesApiFp(this.configuration).timenowGet(partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventTypesApi - axios parameter creator
 * @export
 */
export const EventTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Remove a previously added event type
         * @summary Remove a previously added event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesEventTypeNameDelete: async (partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('eventTypesEventTypeNameDelete', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('eventTypesEventTypeNameDelete', 'clientId', clientId)
            // verify required parameter 'eventTypeName' is not null or undefined
            assertParamExists('eventTypesEventTypeNameDelete', 'eventTypeName', eventTypeName)
            const localVarPath = `/event_types/{event_type_name}/`
                .replace(`{${"event_type_name"}}`, encodeURIComponent(String(eventTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing Event Type
         * @summary Get an existing Event Type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesEventTypeNameGet: async (partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('eventTypesEventTypeNameGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('eventTypesEventTypeNameGet', 'clientId', clientId)
            // verify required parameter 'eventTypeName' is not null or undefined
            assertParamExists('eventTypesEventTypeNameGet', 'eventTypeName', eventTypeName)
            const localVarPath = `/event_types/{event_type_name}/`
                .replace(`{${"event_type_name"}}`, encodeURIComponent(String(eventTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an already added Event type
         * @summary Update an already added Event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {EventsUpdateEventTypeRequest} updateEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesEventTypeNamePatch: async (partnerId: string, clientId: string, eventTypeName: string, updateEventTypeRequest: EventsUpdateEventTypeRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('eventTypesEventTypeNamePatch', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('eventTypesEventTypeNamePatch', 'clientId', clientId)
            // verify required parameter 'eventTypeName' is not null or undefined
            assertParamExists('eventTypesEventTypeNamePatch', 'eventTypeName', eventTypeName)
            // verify required parameter 'updateEventTypeRequest' is not null or undefined
            assertParamExists('eventTypesEventTypeNamePatch', 'updateEventTypeRequest', updateEventTypeRequest)
            const localVarPath = `/event_types/{event_type_name}/`
                .replace(`{${"event_type_name"}}`, encodeURIComponent(String(eventTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Event Types in a Partner
         * @summary Get all Event Types in a Partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesGet: async (partnerId: string, clientId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('eventTypesGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('eventTypesGet', 'clientId', clientId)
            const localVarPath = `/event_types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new Event Type in a partner
         * @summary Add a new Event Type in a partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {EventsEventTypeRequest} eventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesPost: async (partnerId: string, clientId: string, eventTypeRequest: EventsEventTypeRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('eventTypesPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('eventTypesPost', 'clientId', clientId)
            // verify required parameter 'eventTypeRequest' is not null or undefined
            assertParamExists('eventTypesPost', 'eventTypeRequest', eventTypeRequest)
            const localVarPath = `/event_types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a previously added event type
         * @summary Remove a previously added event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesEventTypeNameDelete: async (partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNameDelete', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNameDelete', 'clientId', clientId)
            // verify required parameter 'eventTypeName' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNameDelete', 'eventTypeName', eventTypeName)
            const localVarPath = `/platform/event_types/{event_type_name}/`
                .replace(`{${"event_type_name"}}`, encodeURIComponent(String(eventTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing Event Type
         * @summary Get an existing Event Type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesEventTypeNameGet: async (partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNameGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNameGet', 'clientId', clientId)
            // verify required parameter 'eventTypeName' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNameGet', 'eventTypeName', eventTypeName)
            const localVarPath = `/platform/event_types/{event_type_name}/`
                .replace(`{${"event_type_name"}}`, encodeURIComponent(String(eventTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an already added Event type
         * @summary Update an already added Event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {EventsUpdatePlatformEventTypeRequest} updatePlatformEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesEventTypeNamePatch: async (partnerId: string, clientId: string, eventTypeName: string, updatePlatformEventTypeRequest: EventsUpdatePlatformEventTypeRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNamePatch', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNamePatch', 'clientId', clientId)
            // verify required parameter 'eventTypeName' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNamePatch', 'eventTypeName', eventTypeName)
            // verify required parameter 'updatePlatformEventTypeRequest' is not null or undefined
            assertParamExists('platformEventTypesEventTypeNamePatch', 'updatePlatformEventTypeRequest', updatePlatformEventTypeRequest)
            const localVarPath = `/platform/event_types/{event_type_name}/`
                .replace(`{${"event_type_name"}}`, encodeURIComponent(String(eventTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlatformEventTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Event Types in a Partner
         * @summary Get all Event Types in a Partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesGet: async (partnerId: string, clientId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('platformEventTypesGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('platformEventTypesGet', 'clientId', clientId)
            const localVarPath = `/platform/event_types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new Event Type in a partner
         * @summary Add a new Event Type in a partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {EventsPlatformEventTypeRequest} platformEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesPost: async (partnerId: string, clientId: string, platformEventTypeRequest: EventsPlatformEventTypeRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('platformEventTypesPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('platformEventTypesPost', 'clientId', clientId)
            // verify required parameter 'platformEventTypeRequest' is not null or undefined
            assertParamExists('platformEventTypesPost', 'platformEventTypeRequest', platformEventTypeRequest)
            const localVarPath = `/platform/event_types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(platformEventTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventTypesApi - functional programming interface
 * @export
 */
export const EventTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Remove a previously added event type
         * @summary Remove a previously added event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventTypesEventTypeNameDelete(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventTypesEventTypeNameDelete(partnerId, clientId, eventTypeName, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an existing Event Type
         * @summary Get an existing Event Type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventTypesEventTypeNameGet(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsEventTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventTypesEventTypeNameGet(partnerId, clientId, eventTypeName, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an already added Event type
         * @summary Update an already added Event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {EventsUpdateEventTypeRequest} updateEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventTypesEventTypeNamePatch(partnerId: string, clientId: string, eventTypeName: string, updateEventTypeRequest: EventsUpdateEventTypeRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsEventTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventTypesEventTypeNamePatch(partnerId, clientId, eventTypeName, updateEventTypeRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Event Types in a Partner
         * @summary Get all Event Types in a Partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventTypesGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventsEventTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventTypesGet(partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new Event Type in a partner
         * @summary Add a new Event Type in a partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {EventsEventTypeRequest} eventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventTypesPost(partnerId: string, clientId: string, eventTypeRequest: EventsEventTypeRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsEventTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventTypesPost(partnerId, clientId, eventTypeRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove a previously added event type
         * @summary Remove a previously added event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async platformEventTypesEventTypeNameDelete(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.platformEventTypesEventTypeNameDelete(partnerId, clientId, eventTypeName, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an existing Event Type
         * @summary Get an existing Event Type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async platformEventTypesEventTypeNameGet(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsPlatformEventTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.platformEventTypesEventTypeNameGet(partnerId, clientId, eventTypeName, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an already added Event type
         * @summary Update an already added Event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {EventsUpdatePlatformEventTypeRequest} updatePlatformEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async platformEventTypesEventTypeNamePatch(partnerId: string, clientId: string, eventTypeName: string, updatePlatformEventTypeRequest: EventsUpdatePlatformEventTypeRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsPlatformEventTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.platformEventTypesEventTypeNamePatch(partnerId, clientId, eventTypeName, updatePlatformEventTypeRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all Event Types in a Partner
         * @summary Get all Event Types in a Partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async platformEventTypesGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventsPlatformEventTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.platformEventTypesGet(partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new Event Type in a partner
         * @summary Add a new Event Type in a partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {EventsPlatformEventTypeRequest} platformEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async platformEventTypesPost(partnerId: string, clientId: string, platformEventTypeRequest: EventsPlatformEventTypeRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsEventTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.platformEventTypesPost(partnerId, clientId, platformEventTypeRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventTypesApi - factory interface
 * @export
 */
export const EventTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventTypesApiFp(configuration)
    return {
        /**
         * Remove a previously added event type
         * @summary Remove a previously added event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesEventTypeNameDelete(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any): AxiosPromise<string> {
            return localVarFp.eventTypesEventTypeNameDelete(partnerId, clientId, eventTypeName, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing Event Type
         * @summary Get an existing Event Type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesEventTypeNameGet(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any): AxiosPromise<EventsEventTypeResponse> {
            return localVarFp.eventTypesEventTypeNameGet(partnerId, clientId, eventTypeName, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an already added Event type
         * @summary Update an already added Event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {EventsUpdateEventTypeRequest} updateEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesEventTypeNamePatch(partnerId: string, clientId: string, eventTypeName: string, updateEventTypeRequest: EventsUpdateEventTypeRequest, clientSecret?: string, options?: any): AxiosPromise<EventsEventTypeResponse> {
            return localVarFp.eventTypesEventTypeNamePatch(partnerId, clientId, eventTypeName, updateEventTypeRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Event Types in a Partner
         * @summary Get all Event Types in a Partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): AxiosPromise<Array<EventsEventTypeResponse>> {
            return localVarFp.eventTypesGet(partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new Event Type in a partner
         * @summary Add a new Event Type in a partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {EventsEventTypeRequest} eventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTypesPost(partnerId: string, clientId: string, eventTypeRequest: EventsEventTypeRequest, clientSecret?: string, options?: any): AxiosPromise<EventsEventTypeResponse> {
            return localVarFp.eventTypesPost(partnerId, clientId, eventTypeRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a previously added event type
         * @summary Remove a previously added event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesEventTypeNameDelete(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any): AxiosPromise<string> {
            return localVarFp.platformEventTypesEventTypeNameDelete(partnerId, clientId, eventTypeName, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing Event Type
         * @summary Get an existing Event Type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesEventTypeNameGet(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any): AxiosPromise<EventsPlatformEventTypeResponse> {
            return localVarFp.platformEventTypesEventTypeNameGet(partnerId, clientId, eventTypeName, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an already added Event type
         * @summary Update an already added Event type
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} eventTypeName Event Type Name
         * @param {EventsUpdatePlatformEventTypeRequest} updatePlatformEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesEventTypeNamePatch(partnerId: string, clientId: string, eventTypeName: string, updatePlatformEventTypeRequest: EventsUpdatePlatformEventTypeRequest, clientSecret?: string, options?: any): AxiosPromise<EventsPlatformEventTypeResponse> {
            return localVarFp.platformEventTypesEventTypeNamePatch(partnerId, clientId, eventTypeName, updatePlatformEventTypeRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all Event Types in a Partner
         * @summary Get all Event Types in a Partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): AxiosPromise<Array<EventsPlatformEventTypeResponse>> {
            return localVarFp.platformEventTypesGet(partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new Event Type in a partner
         * @summary Add a new Event Type in a partner
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {EventsPlatformEventTypeRequest} platformEventTypeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformEventTypesPost(partnerId: string, clientId: string, platformEventTypeRequest: EventsPlatformEventTypeRequest, clientSecret?: string, options?: any): AxiosPromise<EventsEventTypeResponse> {
            return localVarFp.platformEventTypesPost(partnerId, clientId, platformEventTypeRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventTypesApi - object-oriented interface
 * @export
 * @class EventTypesApi
 * @extends {BaseAPI}
 */
export class EventTypesApi extends BaseAPI {
    /**
     * Remove a previously added event type
     * @summary Remove a previously added event type
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} eventTypeName Event Type Name
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public eventTypesEventTypeNameDelete(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).eventTypesEventTypeNameDelete(partnerId, clientId, eventTypeName, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing Event Type
     * @summary Get an existing Event Type
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} eventTypeName Event Type Name
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public eventTypesEventTypeNameGet(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).eventTypesEventTypeNameGet(partnerId, clientId, eventTypeName, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an already added Event type
     * @summary Update an already added Event type
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} eventTypeName Event Type Name
     * @param {EventsUpdateEventTypeRequest} updateEventTypeRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public eventTypesEventTypeNamePatch(partnerId: string, clientId: string, eventTypeName: string, updateEventTypeRequest: EventsUpdateEventTypeRequest, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).eventTypesEventTypeNamePatch(partnerId, clientId, eventTypeName, updateEventTypeRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Event Types in a Partner
     * @summary Get all Event Types in a Partner
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public eventTypesGet(partnerId: string, clientId: string, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).eventTypesGet(partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new Event Type in a partner
     * @summary Add a new Event Type in a partner
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {EventsEventTypeRequest} eventTypeRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public eventTypesPost(partnerId: string, clientId: string, eventTypeRequest: EventsEventTypeRequest, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).eventTypesPost(partnerId, clientId, eventTypeRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a previously added event type
     * @summary Remove a previously added event type
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} eventTypeName Event Type Name
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public platformEventTypesEventTypeNameDelete(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).platformEventTypesEventTypeNameDelete(partnerId, clientId, eventTypeName, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing Event Type
     * @summary Get an existing Event Type
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} eventTypeName Event Type Name
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public platformEventTypesEventTypeNameGet(partnerId: string, clientId: string, eventTypeName: string, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).platformEventTypesEventTypeNameGet(partnerId, clientId, eventTypeName, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an already added Event type
     * @summary Update an already added Event type
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} eventTypeName Event Type Name
     * @param {EventsUpdatePlatformEventTypeRequest} updatePlatformEventTypeRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public platformEventTypesEventTypeNamePatch(partnerId: string, clientId: string, eventTypeName: string, updatePlatformEventTypeRequest: EventsUpdatePlatformEventTypeRequest, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).platformEventTypesEventTypeNamePatch(partnerId, clientId, eventTypeName, updatePlatformEventTypeRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all Event Types in a Partner
     * @summary Get all Event Types in a Partner
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public platformEventTypesGet(partnerId: string, clientId: string, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).platformEventTypesGet(partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new Event Type in a partner
     * @summary Add a new Event Type in a partner
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {EventsPlatformEventTypeRequest} platformEventTypeRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypesApi
     */
    public platformEventTypesPost(partnerId: string, clientId: string, platformEventTypeRequest: EventsPlatformEventTypeRequest, clientSecret?: string, options?: any) {
        return EventTypesApiFp(this.configuration).platformEventTypesPost(partnerId, clientId, platformEventTypeRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgDevicesApi - axios parameter creator
 * @export
 */
export const OrgDevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Remove an existing device from organization
         * @summary Remove an existing device from organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdDelete: async (partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDelete', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDelete', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDelete', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDelete', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send Device Commands
         * @summary Send Device Commands
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdDeviceCommandPost: async (partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceCommandPost', 'partnerId', partnerId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceCommandPost', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceCommandPost', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/device-command`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Device Settings
         * @summary Get Device Settings
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdDeviceSettingsGet: async (partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceSettingsGet', 'partnerId', partnerId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceSettingsGet', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceSettingsGet', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/device-settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Device Settings
         * @summary Update Device Settings
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdDeviceSettingsPatch: async (partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceSettingsPatch', 'partnerId', partnerId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceSettingsPatch', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdDeviceSettingsPatch', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/device-settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an event for a device in an organization
         * @summary Retrieve an event for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsEventIdGet: async (partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdGet', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdGet', 'dId', dId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdGet', 'eventId', eventId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/events/{event_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)))
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an event to a device in an organization
         * @summary Update an event to a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {DevicesUpdateEventRequest} updateEventRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsEventIdPatch: async (partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, updateEventRequest: DevicesUpdateEventRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdPatch', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdPatch', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdPatch', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdPatch', 'dId', dId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdPatch', 'eventId', eventId)
            // verify required parameter 'updateEventRequest' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdPatch', 'updateEventRequest', updateEventRequest)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/events/{event_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)))
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an event\'s video streaming URL for a device in an organization
         * @summary Retrieve an event\'s video streaming URL for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet: async (partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet', 'dId', dId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet', 'eventId', eventId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/events/{event_id}/streaming-url`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)))
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an event for a device in an organization
         * @summary Retrieve all events for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsGet: async (partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsGet', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsGet', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/events/`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add an event to a device in an organization
         * @summary Add an event to a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {DevicesEventRequest} eventRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsPost: async (partnerId: string, clientId: string, orgId: string, dId: string, eventRequest: DevicesEventRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsPost', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsPost', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsPost', 'dId', dId)
            // verify required parameter 'eventRequest' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdEventsPost', 'eventRequest', eventRequest)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/events`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of an existing device in the organization
         * @summary Get details of an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdGet: async (partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdGet', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdGet', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing device in organization
         * @summary Update an existing device in organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {DevicesUpdateOrgDeviceRequest} updateOrgDeviceRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdPatch: async (partnerId: string, clientId: string, orgId: string, dId: string, updateOrgDeviceRequest: DevicesUpdateOrgDeviceRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdPatch', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdPatch', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdPatch', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdPatch', 'dId', dId)
            // verify required parameter 'updateOrgDeviceRequest' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdPatch', 'updateOrgDeviceRequest', updateOrgDeviceRequest)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrgDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get pre-signed upload url
         * @summary Get pre-signed upload url
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdPresignedUploadUrlPost: async (orgId: string, dId: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdPresignedUploadUrlPost', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdPresignedUploadUrlPost', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/presigned-upload-url`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get zones from an existing device in the organization
         * @summary Get zones from an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdZonesGet: async (partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesGet', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesGet', 'dId', dId)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/zones`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update zones in an existing device in the organization
         * @summary Update zones in an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {Array<DevicesZone>} zones Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdZonesPut: async (partnerId: string, clientId: string, orgId: string, dId: string, zones: Array<DevicesZone>, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesPut', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesPut', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesPut', 'orgId', orgId)
            // verify required parameter 'dId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesPut', 'dId', dId)
            // verify required parameter 'zones' is not null or undefined
            assertParamExists('orgsOrgIdDevicesDIdZonesPut', 'zones', zones)
            const localVarPath = `/orgs/{org_id}/devices/{d_id}/zones`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"d_id"}}`, encodeURIComponent(String(dId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zones, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all devices in an organization
         * @summary Get all devices in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesGet: async (partnerId: string, clientId: string, orgId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesGet', 'orgId', orgId)
            const localVarPath = `/orgs/{org_id}/devices`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a device to organization
         * @summary Add a device to organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {DevicesCreateOrgDeviceRequest} createOrgDeviceRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesPost: async (partnerId: string, clientId: string, orgId: string, createOrgDeviceRequest: DevicesCreateOrgDeviceRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesPost', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesPost', 'orgId', orgId)
            // verify required parameter 'createOrgDeviceRequest' is not null or undefined
            assertParamExists('orgsOrgIdDevicesPost', 'createOrgDeviceRequest', createOrgDeviceRequest)
            const localVarPath = `/orgs/{org_id}/devices`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrgDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves captured videos and snapshots corresponding to the reference_id of previous ACK
         * @summary Get captured videos and snapshot
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} referenceId Reference Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesVideosGet: async (partnerId: string, clientId: string, orgId: string, referenceId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesVideosGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesVideosGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesVideosGet', 'orgId', orgId)
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesVideosGet', 'referenceId', referenceId)
            const localVarPath = `/orgs/{org_id}/devices/videos`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referenceId !== undefined) {
                localVarQueryParameter['reference_id'] = referenceId;
            }

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Triggers a capture video and snapshot command to OrgDevices and returns a reference_id as ACK
         * @summary Trigger capture videos and snapshot
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesVideosPost: async (partnerId: string, clientId: string, orgId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesVideosPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesVideosPost', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDevicesVideosPost', 'orgId', orgId)
            const localVarPath = `/orgs/{org_id}/devices/videos`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgDevicesApi - functional programming interface
 * @export
 */
export const OrgDevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgDevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Remove an existing device from organization
         * @summary Remove an existing device from organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdDelete(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdDelete(partnerId, clientId, orgId, dId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send Device Commands
         * @summary Send Device Commands
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdDeviceCommandPost(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdDeviceCommandPost(partnerId, orgId, dId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Device Settings
         * @summary Get Device Settings
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdDeviceSettingsGet(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesDeviceSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdDeviceSettingsGet(partnerId, orgId, dId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Device Settings
         * @summary Update Device Settings
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdDeviceSettingsPatch(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesDeviceSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdDeviceSettingsPatch(partnerId, orgId, dId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve an event for a device in an organization
         * @summary Retrieve an event for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdEventsEventIdGet(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdEventsEventIdGet(partnerId, clientId, orgId, dId, eventId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an event to a device in an organization
         * @summary Update an event to a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {DevicesUpdateEventRequest} updateEventRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdEventsEventIdPatch(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, updateEventRequest: DevicesUpdateEventRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdEventsEventIdPatch(partnerId, clientId, orgId, dId, eventId, updateEventRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve an event\'s video streaming URL for a device in an organization
         * @summary Retrieve an event\'s video streaming URL for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesEventStreamingURLResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet(partnerId, clientId, orgId, dId, eventId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve an event for a device in an organization
         * @summary Retrieve all events for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdEventsGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DevicesEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdEventsGet(partnerId, clientId, orgId, dId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add an event to a device in an organization
         * @summary Add an event to a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {DevicesEventRequest} eventRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdEventsPost(partnerId: string, clientId: string, orgId: string, dId: string, eventRequest: DevicesEventRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdEventsPost(partnerId, clientId, orgId, dId, eventRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get details of an existing device in the organization
         * @summary Get details of an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesOrgDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdGet(partnerId, clientId, orgId, dId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing device in organization
         * @summary Update an existing device in organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {DevicesUpdateOrgDeviceRequest} updateOrgDeviceRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdPatch(partnerId: string, clientId: string, orgId: string, dId: string, updateOrgDeviceRequest: DevicesUpdateOrgDeviceRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesOrgDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdPatch(partnerId, clientId, orgId, dId, updateOrgDeviceRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get pre-signed upload url
         * @summary Get pre-signed upload url
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdPresignedUploadUrlPost(orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesPreSignedUploadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdPresignedUploadUrlPost(orgId, dId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get zones from an existing device in the organization
         * @summary Get zones from an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdZonesGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DevicesZone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdZonesGet(partnerId, clientId, orgId, dId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update zones in an existing device in the organization
         * @summary Update zones in an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {Array<DevicesZone>} zones Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesDIdZonesPut(partnerId: string, clientId: string, orgId: string, dId: string, zones: Array<DevicesZone>, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DevicesZone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesDIdZonesPut(partnerId, clientId, orgId, dId, zones, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all devices in an organization
         * @summary Get all devices in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DevicesOrgDeviceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesGet(partnerId, clientId, orgId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a device to organization
         * @summary Add a device to organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {DevicesCreateOrgDeviceRequest} createOrgDeviceRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesPost(partnerId: string, clientId: string, orgId: string, createOrgDeviceRequest: DevicesCreateOrgDeviceRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesOrgDeviceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesPost(partnerId, clientId, orgId, createOrgDeviceRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves captured videos and snapshots corresponding to the reference_id of previous ACK
         * @summary Get captured videos and snapshot
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} referenceId Reference Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesVideosGet(partnerId: string, clientId: string, orgId: string, referenceId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesCaptureVideoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesVideosGet(partnerId, clientId, orgId, referenceId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Triggers a capture video and snapshot command to OrgDevices and returns a reference_id as ACK
         * @summary Trigger capture videos and snapshot
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDevicesVideosPost(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesCaptureVideoAck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDevicesVideosPost(partnerId, clientId, orgId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrgDevicesApi - factory interface
 * @export
 */
export const OrgDevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgDevicesApiFp(configuration)
    return {
        /**
         * Remove an existing device from organization
         * @summary Remove an existing device from organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdDelete(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any): AxiosPromise<string> {
            return localVarFp.orgsOrgIdDevicesDIdDelete(partnerId, clientId, orgId, dId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Send Device Commands
         * @summary Send Device Commands
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdDeviceCommandPost(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<DevicesSuccessResponse> {
            return localVarFp.orgsOrgIdDevicesDIdDeviceCommandPost(partnerId, orgId, dId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Device Settings
         * @summary Get Device Settings
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdDeviceSettingsGet(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<DevicesDeviceSettingResponse> {
            return localVarFp.orgsOrgIdDevicesDIdDeviceSettingsGet(partnerId, orgId, dId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Device Settings
         * @summary Update Device Settings
         * @param {string} partnerId Partner Id
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdDeviceSettingsPatch(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<DevicesDeviceSettingResponse> {
            return localVarFp.orgsOrgIdDevicesDIdDeviceSettingsPatch(partnerId, orgId, dId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an event for a device in an organization
         * @summary Retrieve an event for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsEventIdGet(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, clientSecret?: string, options?: any): AxiosPromise<DevicesEventResponse> {
            return localVarFp.orgsOrgIdDevicesDIdEventsEventIdGet(partnerId, clientId, orgId, dId, eventId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an event to a device in an organization
         * @summary Update an event to a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {DevicesUpdateEventRequest} updateEventRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsEventIdPatch(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, updateEventRequest: DevicesUpdateEventRequest, clientSecret?: string, options?: any): AxiosPromise<DevicesEventResponse> {
            return localVarFp.orgsOrgIdDevicesDIdEventsEventIdPatch(partnerId, clientId, orgId, dId, eventId, updateEventRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an event\'s video streaming URL for a device in an organization
         * @summary Retrieve an event\'s video streaming URL for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} eventId event Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, clientSecret?: string, options?: any): AxiosPromise<DevicesEventStreamingURLResponse> {
            return localVarFp.orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet(partnerId, clientId, orgId, dId, eventId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an event for a device in an organization
         * @summary Retrieve all events for a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any): AxiosPromise<Array<DevicesEventResponse>> {
            return localVarFp.orgsOrgIdDevicesDIdEventsGet(partnerId, clientId, orgId, dId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Add an event to a device in an organization
         * @summary Add an event to a device in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId device Id
         * @param {DevicesEventRequest} eventRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdEventsPost(partnerId: string, clientId: string, orgId: string, dId: string, eventRequest: DevicesEventRequest, clientSecret?: string, options?: any): AxiosPromise<DevicesEventResponse> {
            return localVarFp.orgsOrgIdDevicesDIdEventsPost(partnerId, clientId, orgId, dId, eventRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of an existing device in the organization
         * @summary Get details of an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any): AxiosPromise<DevicesOrgDeviceResponse> {
            return localVarFp.orgsOrgIdDevicesDIdGet(partnerId, clientId, orgId, dId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing device in organization
         * @summary Update an existing device in organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {DevicesUpdateOrgDeviceRequest} updateOrgDeviceRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdPatch(partnerId: string, clientId: string, orgId: string, dId: string, updateOrgDeviceRequest: DevicesUpdateOrgDeviceRequest, clientSecret?: string, options?: any): AxiosPromise<DevicesOrgDeviceResponse> {
            return localVarFp.orgsOrgIdDevicesDIdPatch(partnerId, clientId, orgId, dId, updateOrgDeviceRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get pre-signed upload url
         * @summary Get pre-signed upload url
         * @param {string} orgId Org Id
         * @param {string} dId DId
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdPresignedUploadUrlPost(orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<DevicesPreSignedUploadUrlResponse> {
            return localVarFp.orgsOrgIdDevicesDIdPresignedUploadUrlPost(orgId, dId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get zones from an existing device in the organization
         * @summary Get zones from an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdZonesGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any): AxiosPromise<Array<DevicesZone>> {
            return localVarFp.orgsOrgIdDevicesDIdZonesGet(partnerId, clientId, orgId, dId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update zones in an existing device in the organization
         * @summary Update zones in an existing device in the organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} dId Device Id
         * @param {Array<DevicesZone>} zones Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesDIdZonesPut(partnerId: string, clientId: string, orgId: string, dId: string, zones: Array<DevicesZone>, clientSecret?: string, options?: any): AxiosPromise<Array<DevicesZone>> {
            return localVarFp.orgsOrgIdDevicesDIdZonesPut(partnerId, clientId, orgId, dId, zones, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all devices in an organization
         * @summary Get all devices in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): AxiosPromise<Array<DevicesOrgDeviceResponse>> {
            return localVarFp.orgsOrgIdDevicesGet(partnerId, clientId, orgId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a device to organization
         * @summary Add a device to organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {DevicesCreateOrgDeviceRequest} createOrgDeviceRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesPost(partnerId: string, clientId: string, orgId: string, createOrgDeviceRequest: DevicesCreateOrgDeviceRequest, clientSecret?: string, options?: any): AxiosPromise<DevicesOrgDeviceResponse> {
            return localVarFp.orgsOrgIdDevicesPost(partnerId, clientId, orgId, createOrgDeviceRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves captured videos and snapshots corresponding to the reference_id of previous ACK
         * @summary Get captured videos and snapshot
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} referenceId Reference Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesVideosGet(partnerId: string, clientId: string, orgId: string, referenceId: string, clientSecret?: string, options?: any): AxiosPromise<DevicesCaptureVideoResponse> {
            return localVarFp.orgsOrgIdDevicesVideosGet(partnerId, clientId, orgId, referenceId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Triggers a capture video and snapshot command to OrgDevices and returns a reference_id as ACK
         * @summary Trigger capture videos and snapshot
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDevicesVideosPost(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): AxiosPromise<DevicesCaptureVideoAck> {
            return localVarFp.orgsOrgIdDevicesVideosPost(partnerId, clientId, orgId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgDevicesApi - object-oriented interface
 * @export
 * @class OrgDevicesApi
 * @extends {BaseAPI}
 */
export class OrgDevicesApi extends BaseAPI {
    /**
     * Remove an existing device from organization
     * @summary Remove an existing device from organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId Device Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdDelete(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdDelete(partnerId, clientId, orgId, dId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send Device Commands
     * @summary Send Device Commands
     * @param {string} partnerId Partner Id
     * @param {string} orgId Org Id
     * @param {string} dId DId
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdDeviceCommandPost(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdDeviceCommandPost(partnerId, orgId, dId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Device Settings
     * @summary Get Device Settings
     * @param {string} partnerId Partner Id
     * @param {string} orgId Org Id
     * @param {string} dId DId
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdDeviceSettingsGet(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdDeviceSettingsGet(partnerId, orgId, dId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Device Settings
     * @summary Update Device Settings
     * @param {string} partnerId Partner Id
     * @param {string} orgId Org Id
     * @param {string} dId DId
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdDeviceSettingsPatch(partnerId: string, orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdDeviceSettingsPatch(partnerId, orgId, dId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an event for a device in an organization
     * @summary Retrieve an event for a device in an organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId device Id
     * @param {string} eventId event Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdEventsEventIdGet(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdEventsEventIdGet(partnerId, clientId, orgId, dId, eventId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an event to a device in an organization
     * @summary Update an event to a device in an organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId device Id
     * @param {string} eventId event Id
     * @param {DevicesUpdateEventRequest} updateEventRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdEventsEventIdPatch(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, updateEventRequest: DevicesUpdateEventRequest, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdEventsEventIdPatch(partnerId, clientId, orgId, dId, eventId, updateEventRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an event\'s video streaming URL for a device in an organization
     * @summary Retrieve an event\'s video streaming URL for a device in an organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId device Id
     * @param {string} eventId event Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet(partnerId: string, clientId: string, orgId: string, dId: string, eventId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdEventsEventIdStreamingUrlGet(partnerId, clientId, orgId, dId, eventId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an event for a device in an organization
     * @summary Retrieve all events for a device in an organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId device Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdEventsGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdEventsGet(partnerId, clientId, orgId, dId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add an event to a device in an organization
     * @summary Add an event to a device in an organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId device Id
     * @param {DevicesEventRequest} eventRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdEventsPost(partnerId: string, clientId: string, orgId: string, dId: string, eventRequest: DevicesEventRequest, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdEventsPost(partnerId, clientId, orgId, dId, eventRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get details of an existing device in the organization
     * @summary Get details of an existing device in the organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId Device Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdGet(partnerId, clientId, orgId, dId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing device in organization
     * @summary Update an existing device in organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId Device Id
     * @param {DevicesUpdateOrgDeviceRequest} updateOrgDeviceRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdPatch(partnerId: string, clientId: string, orgId: string, dId: string, updateOrgDeviceRequest: DevicesUpdateOrgDeviceRequest, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdPatch(partnerId, clientId, orgId, dId, updateOrgDeviceRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get pre-signed upload url
     * @summary Get pre-signed upload url
     * @param {string} orgId Org Id
     * @param {string} dId DId
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdPresignedUploadUrlPost(orgId: string, dId: string, clientId?: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdPresignedUploadUrlPost(orgId, dId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get zones from an existing device in the organization
     * @summary Get zones from an existing device in the organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId Device Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdZonesGet(partnerId: string, clientId: string, orgId: string, dId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdZonesGet(partnerId, clientId, orgId, dId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update zones in an existing device in the organization
     * @summary Update zones in an existing device in the organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} dId Device Id
     * @param {Array<DevicesZone>} zones Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesDIdZonesPut(partnerId: string, clientId: string, orgId: string, dId: string, zones: Array<DevicesZone>, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesDIdZonesPut(partnerId, clientId, orgId, dId, zones, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all devices in an organization
     * @summary Get all devices in an organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesGet(partnerId, clientId, orgId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a device to organization
     * @summary Add a device to organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {DevicesCreateOrgDeviceRequest} createOrgDeviceRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesPost(partnerId: string, clientId: string, orgId: string, createOrgDeviceRequest: DevicesCreateOrgDeviceRequest, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesPost(partnerId, clientId, orgId, createOrgDeviceRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves captured videos and snapshots corresponding to the reference_id of previous ACK
     * @summary Get captured videos and snapshot
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} referenceId Reference Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesVideosGet(partnerId: string, clientId: string, orgId: string, referenceId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesVideosGet(partnerId, clientId, orgId, referenceId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Triggers a capture video and snapshot command to OrgDevices and returns a reference_id as ACK
     * @summary Trigger capture videos and snapshot
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgDevicesApi
     */
    public orgsOrgIdDevicesVideosPost(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any) {
        return OrgDevicesApiFp(this.configuration).orgsOrgIdDevicesVideosPost(partnerId, clientId, orgId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgsApi - axios parameter creator
 * @export
 */
export const OrgsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all existing orgs for the current user
         * @summary Get all orgs
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsGet: async (partnerId: string, clientId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsGet', 'clientId', clientId)
            const localVarPath = `/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate BindKey corresponding an Org
         * @summary Generate BindKey corresponding an Org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdBindKeyPost: async (partnerId: string, clientId: string, orgId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdBindKeyPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdBindKeyPost', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdBindKeyPost', 'orgId', orgId)
            const localVarPath = `/orgs/{org_id}/bind-key`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing org level temp credentials for KVS
         * @summary Get an existing org level temp credentials for KVS
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {OrgsStreamCredentialRequest} streamCredentialRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdCredentialsPost: async (partnerId: string, clientId: string, orgId: string, streamCredentialRequest: OrgsStreamCredentialRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdCredentialsPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdCredentialsPost', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdCredentialsPost', 'orgId', orgId)
            // verify required parameter 'streamCredentialRequest' is not null or undefined
            assertParamExists('orgsOrgIdCredentialsPost', 'streamCredentialRequest', streamCredentialRequest)
            const localVarPath = `/orgs/{org_id}/credentials`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(streamCredentialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing org
         * @summary Delete an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDelete: async (partnerId: string, clientId: string, orgId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdDelete', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdDelete', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdDelete', 'orgId', orgId)
            const localVarPath = `/orgs/{org_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an event for an organization
         * @summary Retrieve all events for an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdEventsGet: async (partnerId: string, clientId: string, orgId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdEventsGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdEventsGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdEventsGet', 'orgId', orgId)
            const localVarPath = `/orgs/{org_id}/events`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing org
         * @summary Get an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdGet: async (partnerId: string, clientId: string, orgId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdGet', 'orgId', orgId)
            const localVarPath = `/orgs/{org_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing org that belongs to the current user.
         * @summary Update an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {OrgsUpdateOrgRequest} updateOrgRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdPatch: async (partnerId: string, clientId: string, orgId: string, updateOrgRequest: OrgsUpdateOrgRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdPatch', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdPatch', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdPatch', 'orgId', orgId)
            // verify required parameter 'updateOrgRequest' is not null or undefined
            assertParamExists('orgsOrgIdPatch', 'updateOrgRequest', updateOrgRequest)
            const localVarPath = `/orgs/{org_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrgRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new org
         * @summary Create a new org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {OrgsCreateOrgRequest} createOrgRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsPost: async (partnerId: string, clientId: string, createOrgRequest: OrgsCreateOrgRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsPost', 'clientId', clientId)
            // verify required parameter 'createOrgRequest' is not null or undefined
            assertParamExists('orgsPost', 'createOrgRequest', createOrgRequest)
            const localVarPath = `/orgs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrgRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgsApi - functional programming interface
 * @export
 */
export const OrgsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all existing orgs for the current user
         * @summary Get all orgs
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrgsOrgResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsGet(partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate BindKey corresponding an Org
         * @summary Generate BindKey corresponding an Org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdBindKeyPost(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgsBindKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdBindKeyPost(partnerId, clientId, orgId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an existing org level temp credentials for KVS
         * @summary Get an existing org level temp credentials for KVS
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {OrgsStreamCredentialRequest} streamCredentialRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdCredentialsPost(partnerId: string, clientId: string, orgId: string, streamCredentialRequest: OrgsStreamCredentialRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgsStreamCredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdCredentialsPost(partnerId, clientId, orgId, streamCredentialRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing org
         * @summary Delete an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdDelete(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdDelete(partnerId, clientId, orgId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve an event for an organization
         * @summary Retrieve all events for an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdEventsGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DevicesEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdEventsGet(partnerId, clientId, orgId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an existing org
         * @summary Get an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgsOrgResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdGet(partnerId, clientId, orgId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing org that belongs to the current user.
         * @summary Update an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {OrgsUpdateOrgRequest} updateOrgRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdPatch(partnerId: string, clientId: string, orgId: string, updateOrgRequest: OrgsUpdateOrgRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgsOrgResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdPatch(partnerId, clientId, orgId, updateOrgRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new org
         * @summary Create a new org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {OrgsCreateOrgRequest} createOrgRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsPost(partnerId: string, clientId: string, createOrgRequest: OrgsCreateOrgRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgsOrgResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsPost(partnerId, clientId, createOrgRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrgsApi - factory interface
 * @export
 */
export const OrgsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgsApiFp(configuration)
    return {
        /**
         * Get all existing orgs for the current user
         * @summary Get all orgs
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): AxiosPromise<Array<OrgsOrgResponse>> {
            return localVarFp.orgsGet(partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate BindKey corresponding an Org
         * @summary Generate BindKey corresponding an Org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdBindKeyPost(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): AxiosPromise<OrgsBindKeyResponse> {
            return localVarFp.orgsOrgIdBindKeyPost(partnerId, clientId, orgId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing org level temp credentials for KVS
         * @summary Get an existing org level temp credentials for KVS
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {OrgsStreamCredentialRequest} streamCredentialRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdCredentialsPost(partnerId: string, clientId: string, orgId: string, streamCredentialRequest: OrgsStreamCredentialRequest, clientSecret?: string, options?: any): AxiosPromise<OrgsStreamCredentialResponse> {
            return localVarFp.orgsOrgIdCredentialsPost(partnerId, clientId, orgId, streamCredentialRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing org
         * @summary Delete an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdDelete(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): AxiosPromise<string> {
            return localVarFp.orgsOrgIdDelete(partnerId, clientId, orgId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an event for an organization
         * @summary Retrieve all events for an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdEventsGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): AxiosPromise<Array<DevicesEventResponse>> {
            return localVarFp.orgsOrgIdEventsGet(partnerId, clientId, orgId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing org
         * @summary Get an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): AxiosPromise<OrgsOrgResponse> {
            return localVarFp.orgsOrgIdGet(partnerId, clientId, orgId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing org that belongs to the current user.
         * @summary Update an existing org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {OrgsUpdateOrgRequest} updateOrgRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdPatch(partnerId: string, clientId: string, orgId: string, updateOrgRequest: OrgsUpdateOrgRequest, clientSecret?: string, options?: any): AxiosPromise<OrgsOrgResponse> {
            return localVarFp.orgsOrgIdPatch(partnerId, clientId, orgId, updateOrgRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new org
         * @summary Create a new org
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {OrgsCreateOrgRequest} createOrgRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsPost(partnerId: string, clientId: string, createOrgRequest: OrgsCreateOrgRequest, clientSecret?: string, options?: any): AxiosPromise<OrgsOrgResponse> {
            return localVarFp.orgsPost(partnerId, clientId, createOrgRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgsApi - object-oriented interface
 * @export
 * @class OrgsApi
 * @extends {BaseAPI}
 */
export class OrgsApi extends BaseAPI {
    /**
     * Get all existing orgs for the current user
     * @summary Get all orgs
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsGet(partnerId: string, clientId: string, clientSecret?: string, options?: any) {
        return OrgsApiFp(this.configuration).orgsGet(partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate BindKey corresponding an Org
     * @summary Generate BindKey corresponding an Org
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsOrgIdBindKeyPost(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any) {
        return OrgsApiFp(this.configuration).orgsOrgIdBindKeyPost(partnerId, clientId, orgId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing org level temp credentials for KVS
     * @summary Get an existing org level temp credentials for KVS
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {OrgsStreamCredentialRequest} streamCredentialRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsOrgIdCredentialsPost(partnerId: string, clientId: string, orgId: string, streamCredentialRequest: OrgsStreamCredentialRequest, clientSecret?: string, options?: any) {
        return OrgsApiFp(this.configuration).orgsOrgIdCredentialsPost(partnerId, clientId, orgId, streamCredentialRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing org
     * @summary Delete an existing org
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsOrgIdDelete(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any) {
        return OrgsApiFp(this.configuration).orgsOrgIdDelete(partnerId, clientId, orgId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an event for an organization
     * @summary Retrieve all events for an organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsOrgIdEventsGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any) {
        return OrgsApiFp(this.configuration).orgsOrgIdEventsGet(partnerId, clientId, orgId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing org
     * @summary Get an existing org
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsOrgIdGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any) {
        return OrgsApiFp(this.configuration).orgsOrgIdGet(partnerId, clientId, orgId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing org that belongs to the current user.
     * @summary Update an existing org
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {OrgsUpdateOrgRequest} updateOrgRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsOrgIdPatch(partnerId: string, clientId: string, orgId: string, updateOrgRequest: OrgsUpdateOrgRequest, clientSecret?: string, options?: any) {
        return OrgsApiFp(this.configuration).orgsOrgIdPatch(partnerId, clientId, orgId, updateOrgRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new org
     * @summary Create a new org
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {OrgsCreateOrgRequest} createOrgRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public orgsPost(partnerId: string, clientId: string, createOrgRequest: OrgsCreateOrgRequest, clientSecret?: string, options?: any) {
        return OrgsApiFp(this.configuration).orgsPost(partnerId, clientId, createOrgRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubjectsApi - axios parameter creator
 * @export
 */
export const SubjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all subjects in an organization
         * @summary Get all subjects in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsGet: async (partnerId: string, clientId: string, orgId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsGet', 'orgId', orgId)
            const localVarPath = `/orgs/{org_id}/subjects`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new subject
         * @summary Add a new subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {SubjectsSubjectRequest} subjectRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsPost: async (partnerId: string, clientId: string, orgId: string, subjectRequest: SubjectsSubjectRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsPost', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsPost', 'orgId', orgId)
            // verify required parameter 'subjectRequest' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsPost', 'subjectRequest', subjectRequest)
            const localVarPath = `/orgs/{org_id}/subjects`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a previously added subject
         * @summary Remove a previously added subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsSubjectIdDelete: async (partnerId: string, clientId: string, orgId: string, subjectId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdDelete', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdDelete', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdDelete', 'orgId', orgId)
            // verify required parameter 'subjectId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdDelete', 'subjectId', subjectId)
            const localVarPath = `/orgs/{org_id}/subjects/{subject_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subject_id"}}`, encodeURIComponent(String(subjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing subject
         * @summary Get an existing subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsSubjectIdGet: async (partnerId: string, clientId: string, orgId: string, subjectId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdGet', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdGet', 'orgId', orgId)
            // verify required parameter 'subjectId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdGet', 'subjectId', subjectId)
            const localVarPath = `/orgs/{org_id}/subjects/{subject_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subject_id"}}`, encodeURIComponent(String(subjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an already added subject
         * @summary Update an already added subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {SubjectsUpdateSubjectRequest} updateSubjectRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsSubjectIdPatch: async (partnerId: string, clientId: string, orgId: string, subjectId: string, updateSubjectRequest: SubjectsUpdateSubjectRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdPatch', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdPatch', 'clientId', clientId)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdPatch', 'orgId', orgId)
            // verify required parameter 'subjectId' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdPatch', 'subjectId', subjectId)
            // verify required parameter 'updateSubjectRequest' is not null or undefined
            assertParamExists('orgsOrgIdSubjectsSubjectIdPatch', 'updateSubjectRequest', updateSubjectRequest)
            const localVarPath = `/orgs/{org_id}/subjects/{subject_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subject_id"}}`, encodeURIComponent(String(subjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubjectsApi - functional programming interface
 * @export
 */
export const SubjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all subjects in an organization
         * @summary Get all subjects in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdSubjectsGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<SubjectsSubjectResponse>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdSubjectsGet(partnerId, clientId, orgId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new subject
         * @summary Add a new subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {SubjectsSubjectRequest} subjectRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdSubjectsPost(partnerId: string, clientId: string, orgId: string, subjectRequest: SubjectsSubjectRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectsSubjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdSubjectsPost(partnerId, clientId, orgId, subjectRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove a previously added subject
         * @summary Remove a previously added subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdSubjectsSubjectIdDelete(partnerId: string, clientId: string, orgId: string, subjectId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdSubjectsSubjectIdDelete(partnerId, clientId, orgId, subjectId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an existing subject
         * @summary Get an existing subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdSubjectsSubjectIdGet(partnerId: string, clientId: string, orgId: string, subjectId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectsSubjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdSubjectsSubjectIdGet(partnerId, clientId, orgId, subjectId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an already added subject
         * @summary Update an already added subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {SubjectsUpdateSubjectRequest} updateSubjectRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgsOrgIdSubjectsSubjectIdPatch(partnerId: string, clientId: string, orgId: string, subjectId: string, updateSubjectRequest: SubjectsUpdateSubjectRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubjectsSubjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgsOrgIdSubjectsSubjectIdPatch(partnerId, clientId, orgId, subjectId, updateSubjectRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubjectsApi - factory interface
 * @export
 */
export const SubjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubjectsApiFp(configuration)
    return {
        /**
         * Get all subjects in an organization
         * @summary Get all subjects in an organization
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any): AxiosPromise<Array<Array<SubjectsSubjectResponse>>> {
            return localVarFp.orgsOrgIdSubjectsGet(partnerId, clientId, orgId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new subject
         * @summary Add a new subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {SubjectsSubjectRequest} subjectRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsPost(partnerId: string, clientId: string, orgId: string, subjectRequest: SubjectsSubjectRequest, clientSecret?: string, options?: any): AxiosPromise<SubjectsSubjectResponse> {
            return localVarFp.orgsOrgIdSubjectsPost(partnerId, clientId, orgId, subjectRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a previously added subject
         * @summary Remove a previously added subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsSubjectIdDelete(partnerId: string, clientId: string, orgId: string, subjectId: string, clientSecret?: string, options?: any): AxiosPromise<string> {
            return localVarFp.orgsOrgIdSubjectsSubjectIdDelete(partnerId, clientId, orgId, subjectId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing subject
         * @summary Get an existing subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsSubjectIdGet(partnerId: string, clientId: string, orgId: string, subjectId: string, clientSecret?: string, options?: any): AxiosPromise<SubjectsSubjectResponse> {
            return localVarFp.orgsOrgIdSubjectsSubjectIdGet(partnerId, clientId, orgId, subjectId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an already added subject
         * @summary Update an already added subject
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} orgId Org Id
         * @param {string} subjectId Subject Id
         * @param {SubjectsUpdateSubjectRequest} updateSubjectRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgsOrgIdSubjectsSubjectIdPatch(partnerId: string, clientId: string, orgId: string, subjectId: string, updateSubjectRequest: SubjectsUpdateSubjectRequest, clientSecret?: string, options?: any): AxiosPromise<SubjectsSubjectResponse> {
            return localVarFp.orgsOrgIdSubjectsSubjectIdPatch(partnerId, clientId, orgId, subjectId, updateSubjectRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubjectsApi - object-oriented interface
 * @export
 * @class SubjectsApi
 * @extends {BaseAPI}
 */
export class SubjectsApi extends BaseAPI {
    /**
     * Get all subjects in an organization
     * @summary Get all subjects in an organization
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public orgsOrgIdSubjectsGet(partnerId: string, clientId: string, orgId: string, clientSecret?: string, options?: any) {
        return SubjectsApiFp(this.configuration).orgsOrgIdSubjectsGet(partnerId, clientId, orgId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new subject
     * @summary Add a new subject
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {SubjectsSubjectRequest} subjectRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public orgsOrgIdSubjectsPost(partnerId: string, clientId: string, orgId: string, subjectRequest: SubjectsSubjectRequest, clientSecret?: string, options?: any) {
        return SubjectsApiFp(this.configuration).orgsOrgIdSubjectsPost(partnerId, clientId, orgId, subjectRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a previously added subject
     * @summary Remove a previously added subject
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} subjectId Subject Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public orgsOrgIdSubjectsSubjectIdDelete(partnerId: string, clientId: string, orgId: string, subjectId: string, clientSecret?: string, options?: any) {
        return SubjectsApiFp(this.configuration).orgsOrgIdSubjectsSubjectIdDelete(partnerId, clientId, orgId, subjectId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing subject
     * @summary Get an existing subject
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} subjectId Subject Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public orgsOrgIdSubjectsSubjectIdGet(partnerId: string, clientId: string, orgId: string, subjectId: string, clientSecret?: string, options?: any) {
        return SubjectsApiFp(this.configuration).orgsOrgIdSubjectsSubjectIdGet(partnerId, clientId, orgId, subjectId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an already added subject
     * @summary Update an already added subject
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} orgId Org Id
     * @param {string} subjectId Subject Id
     * @param {SubjectsUpdateSubjectRequest} updateSubjectRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public orgsOrgIdSubjectsSubjectIdPatch(partnerId: string, clientId: string, orgId: string, subjectId: string, updateSubjectRequest: SubjectsUpdateSubjectRequest, clientSecret?: string, options?: any) {
        return SubjectsApiFp(this.configuration).orgsOrgIdSubjectsSubjectIdPatch(partnerId, clientId, orgId, subjectId, updateSubjectRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Used to infer if email is available for registration When Partner-ID header is present the check is made under the given partner When Partner-ID header is not present the check is made across all partners who are using generic app
         * @summary Check if given email exists
         * @param {string} email Email Id
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersEmailsEmailExistsGet: async (email: string, partnerId?: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersEmailsEmailExistsGet', 'email', email)
            const localVarPath = `/users/emails/{email}/exists`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends a password reset link to the user\'s email address
         * @summary Send reset password email
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} email Email Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersEmailsEmailResetPasswordPost: async (partnerId: string, clientId: string, email: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersEmailsEmailResetPasswordPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersEmailsEmailResetPasswordPost', 'clientId', clientId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersEmailsEmailResetPasswordPost', 'email', email)
            const localVarPath = `/users/emails/{email}/reset-password`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends a email verification link to the user\'s email address
         * @summary Send verification email
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} email Email Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersEmailsEmailVerifyPost: async (partnerId: string, clientId: string, email: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersEmailsEmailVerifyPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersEmailsEmailVerifyPost', 'clientId', clientId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersEmailsEmailVerifyPost', 'email', email)
            const localVarPath = `/users/emails/{email}/verify`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all existing user
         * @summary Get all existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (partnerId: string, clientId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersGet', 'clientId', clientId)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user
         * @summary Create a new user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersCreateUserRequest} createUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (partnerId: string, clientId: string, createUserRequest: UsersCreateUserRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersPost', 'clientId', clientId)
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('usersPost', 'createUserRequest', createUserRequest)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new user and sends a verification email
         * @summary Sign up a new user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersSignUpUserRequest} signUpUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSignupPost: async (partnerId: string, clientId: string, signUpUserRequest: UsersSignUpUserRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersSignupPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersSignupPost', 'clientId', clientId)
            // verify required parameter 'signUpUserRequest' is not null or undefined
            assertParamExists('usersSignupPost', 'signUpUserRequest', signUpUserRequest)
            const localVarPath = `/users/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exchange an existing token created for a specific client for a new token targeted to a different client
         * @summary Exchange an existing token to a different token
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersTokenExchangeRequest} tokenExchangeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersTokenExchangePost: async (partnerId: string, clientId: string, tokenExchangeRequest: UsersTokenExchangeRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersTokenExchangePost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersTokenExchangePost', 'clientId', clientId)
            // verify required parameter 'tokenExchangeRequest' is not null or undefined
            assertParamExists('usersTokenExchangePost', 'tokenExchangeRequest', tokenExchangeRequest)
            const localVarPath = `/users/token-exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenExchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set user password
         * @summary Set user password
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersSetPasswordRequest} setPasswordRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdCredentialsPost: async (partnerId: string, clientId: string, userId: string, setPasswordRequest: UsersSetPasswordRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersUserIdCredentialsPost', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersUserIdCredentialsPost', 'clientId', clientId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdCredentialsPost', 'userId', userId)
            // verify required parameter 'setPasswordRequest' is not null or undefined
            assertParamExists('usersUserIdCredentialsPost', 'setPasswordRequest', setPasswordRequest)
            const localVarPath = `/users/{user_id}/credentials`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing user
         * @summary Delete an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdDelete: async (partnerId: string, clientId: string, userId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersUserIdDelete', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersUserIdDelete', 'clientId', clientId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdDelete', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an existing user
         * @summary Get an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdGet: async (partnerId: string, clientId: string, userId: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersUserIdGet', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersUserIdGet', 'clientId', clientId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdGet', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing user
         * @summary Update an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersUpdateUserRequest} updateUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdPatch: async (partnerId: string, clientId: string, userId: string, updateUserRequest: UsersUpdateUserRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersUserIdPatch', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersUserIdPatch', 'clientId', clientId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdPatch', 'userId', userId)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('usersUserIdPatch', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replace an existing user
         * @summary Replace an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersReplaceUserRequest} replaceUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdPut: async (partnerId: string, clientId: string, userId: string, replaceUserRequest: UsersReplaceUserRequest, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('usersUserIdPut', 'partnerId', partnerId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('usersUserIdPut', 'clientId', clientId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUserIdPut', 'userId', userId)
            // verify required parameter 'replaceUserRequest' is not null or undefined
            assertParamExists('usersUserIdPut', 'replaceUserRequest', replaceUserRequest)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Used to infer if username is available for registration When Partner-ID header is present the check is made under the given partner When Partner-ID header is not present the check is made across all partners who are using generic app
         * @summary Check if given username exists
         * @param {string} username Username
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernamesUsernameExistsGet: async (username: string, partnerId?: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUsernamesUsernameExistsGet', 'username', username)
            const localVarPath = `/users/usernames/{username}/exists`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined && partnerId !== null) {
                localVarHeaderParameter['Partner-Id'] = String(partnerId);
            }

            if (clientId !== undefined && clientId !== null) {
                localVarHeaderParameter['Client-Id'] = String(clientId);
            }

            if (clientSecret !== undefined && clientSecret !== null) {
                localVarHeaderParameter['Client-Secret'] = String(clientSecret);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Used to infer if email is available for registration When Partner-ID header is present the check is made under the given partner When Partner-ID header is not present the check is made across all partners who are using generic app
         * @summary Check if given email exists
         * @param {string} email Email Id
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersEmailsEmailExistsGet(email: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserExistsInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersEmailsEmailExistsGet(email, partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends a password reset link to the user\'s email address
         * @summary Send reset password email
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} email Email Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersEmailsEmailResetPasswordPost(partnerId: string, clientId: string, email: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersSendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersEmailsEmailResetPasswordPost(partnerId, clientId, email, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends a email verification link to the user\'s email address
         * @summary Send verification email
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} email Email Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersEmailsEmailVerifyPost(partnerId: string, clientId: string, email: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersSendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersEmailsEmailVerifyPost(partnerId, clientId, email, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all existing user
         * @summary Get all existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsersUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new user
         * @summary Create a new user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersCreateUserRequest} createUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(partnerId: string, clientId: string, createUserRequest: UsersCreateUserRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(partnerId, clientId, createUserRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register a new user and sends a verification email
         * @summary Sign up a new user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersSignUpUserRequest} signUpUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSignupPost(partnerId: string, clientId: string, signUpUserRequest: UsersSignUpUserRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSignupPost(partnerId, clientId, signUpUserRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exchange an existing token created for a specific client for a new token targeted to a different client
         * @summary Exchange an existing token to a different token
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersTokenExchangeRequest} tokenExchangeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersTokenExchangePost(partnerId: string, clientId: string, tokenExchangeRequest: UsersTokenExchangeRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersTokenExchangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersTokenExchangePost(partnerId, clientId, tokenExchangeRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set user password
         * @summary Set user password
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersSetPasswordRequest} setPasswordRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdCredentialsPost(partnerId: string, clientId: string, userId: string, setPasswordRequest: UsersSetPasswordRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdCredentialsPost(partnerId, clientId, userId, setPasswordRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing user
         * @summary Delete an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdDelete(partnerId: string, clientId: string, userId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdDelete(partnerId, clientId, userId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get an existing user
         * @summary Get an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdGet(partnerId: string, clientId: string, userId: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdGet(partnerId, clientId, userId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing user
         * @summary Update an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersUpdateUserRequest} updateUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdPatch(partnerId: string, clientId: string, userId: string, updateUserRequest: UsersUpdateUserRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdPatch(partnerId, clientId, userId, updateUserRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replace an existing user
         * @summary Replace an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersReplaceUserRequest} replaceUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIdPut(partnerId: string, clientId: string, userId: string, replaceUserRequest: UsersReplaceUserRequest, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdPut(partnerId, clientId, userId, replaceUserRequest, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Used to infer if username is available for registration When Partner-ID header is present the check is made under the given partner When Partner-ID header is not present the check is made across all partners who are using generic app
         * @summary Check if given username exists
         * @param {string} username Username
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUsernamesUsernameExistsGet(username: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserExistsInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUsernamesUsernameExistsGet(username, partnerId, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Used to infer if email is available for registration When Partner-ID header is present the check is made under the given partner When Partner-ID header is not present the check is made across all partners who are using generic app
         * @summary Check if given email exists
         * @param {string} email Email Id
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersEmailsEmailExistsGet(email: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<UsersUserExistsInResponse> {
            return localVarFp.usersEmailsEmailExistsGet(email, partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends a password reset link to the user\'s email address
         * @summary Send reset password email
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} email Email Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersEmailsEmailResetPasswordPost(partnerId: string, clientId: string, email: string, clientSecret?: string, options?: any): AxiosPromise<UsersSendResponse> {
            return localVarFp.usersEmailsEmailResetPasswordPost(partnerId, clientId, email, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends a email verification link to the user\'s email address
         * @summary Send verification email
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} email Email Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersEmailsEmailVerifyPost(partnerId: string, clientId: string, email: string, clientSecret?: string, options?: any): AxiosPromise<UsersSendResponse> {
            return localVarFp.usersEmailsEmailVerifyPost(partnerId, clientId, email, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all existing user
         * @summary Get all existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(partnerId: string, clientId: string, clientSecret?: string, options?: any): AxiosPromise<Array<UsersUserResponse>> {
            return localVarFp.usersGet(partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user
         * @summary Create a new user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersCreateUserRequest} createUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(partnerId: string, clientId: string, createUserRequest: UsersCreateUserRequest, clientSecret?: string, options?: any): AxiosPromise<UsersUserResponse> {
            return localVarFp.usersPost(partnerId, clientId, createUserRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new user and sends a verification email
         * @summary Sign up a new user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersSignUpUserRequest} signUpUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSignupPost(partnerId: string, clientId: string, signUpUserRequest: UsersSignUpUserRequest, clientSecret?: string, options?: any): AxiosPromise<UsersUserResponse> {
            return localVarFp.usersSignupPost(partnerId, clientId, signUpUserRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Exchange an existing token created for a specific client for a new token targeted to a different client
         * @summary Exchange an existing token to a different token
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {UsersTokenExchangeRequest} tokenExchangeRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersTokenExchangePost(partnerId: string, clientId: string, tokenExchangeRequest: UsersTokenExchangeRequest, clientSecret?: string, options?: any): AxiosPromise<UsersTokenExchangeResponse> {
            return localVarFp.usersTokenExchangePost(partnerId, clientId, tokenExchangeRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Set user password
         * @summary Set user password
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersSetPasswordRequest} setPasswordRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdCredentialsPost(partnerId: string, clientId: string, userId: string, setPasswordRequest: UsersSetPasswordRequest, clientSecret?: string, options?: any): AxiosPromise<string> {
            return localVarFp.usersUserIdCredentialsPost(partnerId, clientId, userId, setPasswordRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing user
         * @summary Delete an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdDelete(partnerId: string, clientId: string, userId: string, clientSecret?: string, options?: any): AxiosPromise<string> {
            return localVarFp.usersUserIdDelete(partnerId, clientId, userId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an existing user
         * @summary Get an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdGet(partnerId: string, clientId: string, userId: string, clientSecret?: string, options?: any): AxiosPromise<UsersUserResponse> {
            return localVarFp.usersUserIdGet(partnerId, clientId, userId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing user
         * @summary Update an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersUpdateUserRequest} updateUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdPatch(partnerId: string, clientId: string, userId: string, updateUserRequest: UsersUpdateUserRequest, clientSecret?: string, options?: any): AxiosPromise<UsersUserResponse> {
            return localVarFp.usersUserIdPatch(partnerId, clientId, userId, updateUserRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Replace an existing user
         * @summary Replace an existing user
         * @param {string} partnerId Partner Id
         * @param {string} clientId Client Id
         * @param {string} userId User Id
         * @param {UsersReplaceUserRequest} replaceUserRequest Request body
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIdPut(partnerId: string, clientId: string, userId: string, replaceUserRequest: UsersReplaceUserRequest, clientSecret?: string, options?: any): AxiosPromise<UsersUserResponse> {
            return localVarFp.usersUserIdPut(partnerId, clientId, userId, replaceUserRequest, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Used to infer if username is available for registration When Partner-ID header is present the check is made under the given partner When Partner-ID header is not present the check is made across all partners who are using generic app
         * @summary Check if given username exists
         * @param {string} username Username
         * @param {string} [partnerId] Partner Id
         * @param {string} [clientId] Client Id
         * @param {string} [clientSecret] Client Secret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUsernamesUsernameExistsGet(username: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<UsersUserExistsInResponse> {
            return localVarFp.usersUsernamesUsernameExistsGet(username, partnerId, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Used to infer if email is available for registration When Partner-ID header is present the check is made under the given partner When Partner-ID header is not present the check is made across all partners who are using generic app
     * @summary Check if given email exists
     * @param {string} email Email Id
     * @param {string} [partnerId] Partner Id
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersEmailsEmailExistsGet(email: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersEmailsEmailExistsGet(email, partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends a password reset link to the user\'s email address
     * @summary Send reset password email
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} email Email Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersEmailsEmailResetPasswordPost(partnerId: string, clientId: string, email: string, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersEmailsEmailResetPasswordPost(partnerId, clientId, email, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends a email verification link to the user\'s email address
     * @summary Send verification email
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} email Email Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersEmailsEmailVerifyPost(partnerId: string, clientId: string, email: string, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersEmailsEmailVerifyPost(partnerId, clientId, email, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all existing user
     * @summary Get all existing user
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGet(partnerId: string, clientId: string, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersGet(partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new user
     * @summary Create a new user
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {UsersCreateUserRequest} createUserRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPost(partnerId: string, clientId: string, createUserRequest: UsersCreateUserRequest, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersPost(partnerId, clientId, createUserRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new user and sends a verification email
     * @summary Sign up a new user
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {UsersSignUpUserRequest} signUpUserRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSignupPost(partnerId: string, clientId: string, signUpUserRequest: UsersSignUpUserRequest, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersSignupPost(partnerId, clientId, signUpUserRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exchange an existing token created for a specific client for a new token targeted to a different client
     * @summary Exchange an existing token to a different token
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {UsersTokenExchangeRequest} tokenExchangeRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersTokenExchangePost(partnerId: string, clientId: string, tokenExchangeRequest: UsersTokenExchangeRequest, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersTokenExchangePost(partnerId, clientId, tokenExchangeRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set user password
     * @summary Set user password
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} userId User Id
     * @param {UsersSetPasswordRequest} setPasswordRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIdCredentialsPost(partnerId: string, clientId: string, userId: string, setPasswordRequest: UsersSetPasswordRequest, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersUserIdCredentialsPost(partnerId, clientId, userId, setPasswordRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing user
     * @summary Delete an existing user
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} userId User Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIdDelete(partnerId: string, clientId: string, userId: string, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersUserIdDelete(partnerId, clientId, userId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an existing user
     * @summary Get an existing user
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} userId User Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIdGet(partnerId: string, clientId: string, userId: string, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersUserIdGet(partnerId, clientId, userId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing user
     * @summary Update an existing user
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} userId User Id
     * @param {UsersUpdateUserRequest} updateUserRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIdPatch(partnerId: string, clientId: string, userId: string, updateUserRequest: UsersUpdateUserRequest, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersUserIdPatch(partnerId, clientId, userId, updateUserRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replace an existing user
     * @summary Replace an existing user
     * @param {string} partnerId Partner Id
     * @param {string} clientId Client Id
     * @param {string} userId User Id
     * @param {UsersReplaceUserRequest} replaceUserRequest Request body
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIdPut(partnerId: string, clientId: string, userId: string, replaceUserRequest: UsersReplaceUserRequest, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersUserIdPut(partnerId, clientId, userId, replaceUserRequest, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Used to infer if username is available for registration When Partner-ID header is present the check is made under the given partner When Partner-ID header is not present the check is made across all partners who are using generic app
     * @summary Check if given username exists
     * @param {string} username Username
     * @param {string} [partnerId] Partner Id
     * @param {string} [clientId] Client Id
     * @param {string} [clientSecret] Client Secret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUsernamesUsernameExistsGet(username: string, partnerId?: string, clientId?: string, clientSecret?: string, options?: any) {
        return UsersApiFp(this.configuration).usersUsernamesUsernameExistsGet(username, partnerId, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


