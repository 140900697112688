function decodeEnv(env: string) {
    switch(env) {
      case 'dev':
      case 'prod':
      case 'local':
        return env
      case 'development': return 'dev'
      case 'production': return 'prod'
      default: return 'staging'
    }
  }
  
  export default decodeEnv