import { Button, Input } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Title2 } from '../atoms/Title'
import Wrapper from '../atoms/Wrapper'

const NexButton = styled(Button)`
    margin-top: 20px;
    width: 100%;
`

const EmailVerificationInput = () => {
    return (
        <Wrapper>
            <Title2>What is your email?</Title2>
            <Input size="large" placeholder="Email Address" style={{backgroundColor: 'transparent'}} />
            <NexButton type="primary" size="large">Next</NexButton>
        </Wrapper>
    )
}

export default EmailVerificationInput
